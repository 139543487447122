.__upcoming,
.__featured {
    @apply space-y-2 lg:space-y-8;
}

.__featured-genres {
    @apply space-y-2 lg:space-y-8 overflow-x-hidden;

    .__genres {
        @apply flex space-x-2 overflow-y-auto pb-2 lg:pb-4;
    }
}

.genre__label {
    @apply capitalize font-semibold text-sm text-cerulean-950;
}

.__about {
    @apply mx-auto space-y-2 sm:space-y-4 md:space-y-6 text-center lg:w-2/3 xl:w-13/24;

    .__header {
        @apply text-2xl font-bold tracking-tight text-white lg:text-5xl;
    }

    .__text {
        @apply text-sm text-slate-400 sm:text-lg lg:text-2xl;
    }

    .__button_container {
        @apply items-center justify-center space-x-2 sm:mx-auto sm:w-3/5 lg:w-2/3 xl:w-13/24 lg:flex hidden;

        .__button {
            @apply flex-1 sm:flex-none sm:w-1/2 lg:w-56 lg:mx-auto;
        }
    }
}
