.grouped-result {
    @apply relative space-y-2 sm:space-y-4;

    .grouped-result__header {
        @apply flex items-center;

        [class*='page-header'] {
            @apply flex-1;
        }
    }

    .__grouped-result-tile {
        @apply block h-full w-full flex-none overflow-hidden;
    }
}
