.carousel-container {
    @apply relative;

    .__slides {
        @apply relative flex w-full overflow-x-scroll py-2 md:py-0;

        @media screen and (min-width: 768px) {
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }

        .carousel__slide {
            @apply w-40 flex-none sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5;
        }
    }

    .__slide-controls {
        @apply absolute top-0 hidden h-full items-center transition-opacity duration-300 md:flex lg:opacity-0;
    }

    .__prev--slides {
        @apply -left-11 md:-left-14;

        &.__slide-controls {
            @apply pr-1 md:pr-4;
        }
    }

    .__next--slides {
        @apply -right-11 md:-right-14;

        &.__slide-controls {
            @apply pl-1 md:pl-4;
        }
    }

    &:hover {
        .__slide-controls {
            @apply opacity-100;
        }
    }
}
