.no-results-found__container {
    @apply flex w-full items-center justify-center py-8;

    .no-results-found {
        @apply text-center text-xl font-medium tracking-tight text-white;
    }

    .__generic-container {
        @apply space-y-4 flex flex-col w-full md:w-104;

        .__icon {
            @apply bg-bright-turquoise-500 text-cerulean-950 rounded-full p-2.5 flex items-center justify-center;
        }

        .__message {
            @apply text-white;
        }
    }

    &.--left {
        @apply justify-start p-0;

        .__generic-container {
            @apply items-start;

            .__message {
                @apply text-left;
            }
        }
    }

    &.--center {
        @apply justify-center;

        .__generic-container {
            @apply items-center;

            .__message {
                @apply text-center;
            }
        }
    }
}
